.underConstruction_img{
    height: 99vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.underConstruction_img>object{
    width: 70%;
}

  /* Medium devices (tablets)*/
  @media (max-width: 992px) {
    .underConstruction_img>object{
        width: 80%;
    }
  }
  
  /* Small devices (landscape /phones,)*/
  @media (max-width: 767px) {
    .underConstruction_img>object{
        width: 90%;
    }
  }